import store from "../store/store.js";

class OwnLib {
  constructor() {}

  /**
   *  @param 车牌号
   * */
  replaceCarNum(data) {
    if (data != undefined && typeof data == "string" && data.length >= 6) {
      let xx = data.slice(2, data.length - 2);
      let values = data.replace(xx, "***");
      return values;
    } else {
      return;
    }
  }

  /**
   * @param user Name
   * is Chinese name
   * */

  isNameAvailable(name) {
    let nameReg = /^[\u4e00-\u9fa5]{1,10}$/;
    if (!nameReg.test(name)) {
      return false;
    } else {
      return true;
    }
  }

  /**
   *@param phone number
   *
   **/

  isPoneAvailable(pone) {
    let myreg = /^[1][3,4,5,6,7,8][0-9]{9}$/;
    if (!myreg.test(pone)) {
      return false;
    } else {
      return true;
    }
  }

  //根据传入的值计算时间间距
  diffTime(startDate, endDate) {
    var diff = endDate.getTime() - startDate; //.getTime();//时间差的毫秒数

    //计算出相差天数
    var days = Math.floor(diff / (24 * 3600 * 1000));

    //计算出小时数
    var leave1 = diff % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
    var hours = Math.floor(leave1 / (3600 * 1000));
    //计算相差分钟数
    var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
    var minutes = Math.floor(leave2 / (60 * 1000));

    //计算相差秒数
    var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
    var seconds = Math.round(leave3 / 1000);

    var returnStr = seconds + "秒前";
    if (minutes > 0) {
      returnStr = minutes + "分钟前"; //+ returnStr;
    }
    if (hours > 0) {
      returnStr = hours + "小时前"; // + returnStr;
    }
    if (days > 0) {
      returnStr = days + "天前"; //+ returnStr;
    }
    return returnStr;
  }

  // 判断手机系统跳转市场;
  downApp(IOSURL, AndroidUrl) {
    if (!IOSURL || !AndroidUrl) {
      throw new Error("url不能为空");
    }
    let u = navigator.userAgent.toLocaleLowerCase();

    if (!u.includes("iphone") && !u.includes("ipad")) {
      // 微信中
      if (/MicroMessenger/gi.test(u)) {
        // 尝试跳转
        window.location.href = AndroidUrl;
        // 引导打开浏览器页开启
        store.commit("ChangeShowPoint", true);
        return;
      }
      // 安卓
      openApp(AndroidUrl, () => {
        window.location.href = "https://www.cencobuy.cn/";
      });
    } else {
      // ios
      window.location.href = "https://www.cencobuy.cn/";
    }

    // 检查app是否打开
    function checkOpen(cb) {
      const clickTime = +new Date();
      function check(elsTime) {
        if (elsTime > 3000 || document.hidden || document.webkitHidden) {
          cb(true);
        } else {
          cb(false);
        }
      }
      // 启动间隔20ms运行的定时器，并检测累计消耗时间是否超过3000ms，超过则结束
      let count = 0;
      let intHandle = null;
      intHandle = setInterval(() => {
        count++;
        const elsTime = +new Date() - clickTime;
        if (count >= 100 || elsTime > 3000) {
          clearInterval(intHandle);
          check(elsTime);
        }
      }, 20);
    }
    /**
     * 唤起app
     * @param {any} uri  app schema
     * @param {any} fallback  唤起失败回调
     */
    function openApp(uri, fallback) {
      // const ifr = document.createElement("iframe");
      // ifr.src = uri;
      // ifr.style.display = "none";
      // document.body.appendChild(ifr);
      window.location.href = uri;
      if (fallback) {
        checkOpen((opened) => {
          if (!opened) {
            fallback();
          }
        });
      }
      // setTimeout(() => {
      //   document.body.removeChild(ifr);
      // }, 2000);
    }
  }

  //根据规则切割字符串，返回一个对象
  splitString(data) {
    let datatype = typeof data == "string";
    if (datatype) {
      let list = data.split(",");
      let rel = [];
      for (let i = 0; i < list.length; i++) {
        let obj = list[i];
        rel.push(obj);
      }
      return rel;
    }
  }

  //年少无知写的数组内对象属性查重
  CheckArr(arr, key) {
    const arr1 = arr;
    const list = [];
    let isRepeat = null;
    if (!(arr1 instanceof Array)) {
      throw new Error("传入的必须为一个数组");
    }
    arr1.forEach((value) => {
      list.push(value[key]);
    });
    isRepeat = this.ret(list);
    // true 代表重复 false 代表不重复
    return !isRepeat;
  }
  ret(arr) {
    var res = [arr[0]];
    for (let j = 1; j < arr.length; j++) {
      var repeat = false;
      for (let i = 0; i < res.length; i++) {
        if (arr[j] == res[i]) {
          repeat = true;
          return false;
        }
      }
      if (!repeat) {
        res.push(arr[j]);
      }
    }
    return true;
  }
}
export default OwnLib;
