<template>
  <div id="app">
    <div @click="hidePoint" :class="{ show: showPoint }" class="point-box">
      <div class="point-to-browser">
        <!-- <img src="assets/logo.png" alt="" /> -->
      </div>
    </div>
    <keep-alive>
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "app",
  computed: {
    ...mapState(["showPoint"]),
  },

  data() {
    return {};
  },
  watch: {},
  beforeCreate() {
    if (!/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      window.location.href = "https://www.cencobuy.cn/";
    }
  },
  methods: {
    ...mapMutations(["ChangeShowPoint"]),
    hidePoint() {
      this.ChangeShowPoint(false);
    },
  },
};
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body {
  margin: 0;
  padding: 0;
}

//#nav {
//  padding: 30px;
//
//  a {
//    font-weight: bold;
//    color: #2c3e50;
//
//    &.router-link-exact-active {
//      color: #42b983;
//    }
//  }
//}
body {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: "PingFangSC-Regular", "Source Han Sans CN";
  // background-color:#333237
}
.point-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: -1;
  transition: opacity 0.4s ease;
  opacity: 0;
  &.show {
    z-index: 999;
    opacity: 1;
  }
  .point-to-browser {
    background: #f9f9f9;
    margin: 0 20px;
    padding: 10px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    img {
      width: 100%;
    }
  }
}
</style>
