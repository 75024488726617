import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    name: "小米",
    carInfo: {},
    productInfo: {},
    jumpInfo: {
      AndroidURL: "",
      IOSURL: "",
      showButtonMessage: "",
    },
    videoInfo: {
      videoPlayInfo: {},
      videoCommitInfo: {},
    },
    // type: 1 新车 2 二手车
    transactionCarInfo: {},
    // 安卓 提示打开 浏览器
    showPoint: false,
  },
  mutations: {
    edit: (state, name) => {
      state.name = name.payload;
    },
    //新车 二手车 2019 - 7 - 10
    EditTransactionCarInfoMutation: (state, transactionCarInfo) => {
      state.transactionCarInfo = transactionCarInfo;
    },
    //
    EditCarInfoMutation: (state, carInfo) => {
      state.carInfo = carInfo;
    },
    EditProductInfoMutation: (state, productInfo) => {
      state.productInfo = productInfo;
    },
    EditJumpInfoMutation: (state, jumpInfo) => {
      state.jumpInfo.showButtonMessage = jumpInfo.showButtonMessage || "";
      state.jumpInfo.IOSURL = jumpInfo.IOSURL || "";
      state.jumpInfo.AndroidURL = jumpInfo.AndroidURL || "";
    },
    EditVideoPlayInfoMutation: (state, videoInfo) => {
      state.videoInfo.videoPlayInfo = videoInfo || {};
    },
    EditVideoCommitInfoMutation: (state, videoCommitInfo) => {
      state.videoInfo.videoCommitInfo = videoCommitInfo || {};
    },
    ChangeShowPoint: (state, bool) => {
      state.showPoint = bool;
    },
  },
  //异步处理
  actions: {
    aEdit(context, payload) {
      setTimeout(() => {
        context.commit("edit", payload);
      }, 2000);
    },

    //新车 二手车 2019-7 -10
    EditTransactionCarInfoAction({ commit }, transactionCarInfo) {
      commit("EditTransactionCarInfoMutation", transactionCarInfo);
    },
    //
    EditCarInfoAction({ commit }, carInfo) {
      commit("EditCarInfoMutation", carInfo);
    },

    EditProductInfoAction({ commit }, productInfo) {
      commit("EditProductInfoMutation", productInfo);
    },
    EditJumpInfoAction({ commit }, jumpInfo) {
      if (
        !jumpInfo.showButtonMessage ||
        !jumpInfo.IOSURL ||
        !jumpInfo.AndroidURL
      ) {
        throw new Error("输入的值必须包含ios,android,和按钮显示的值");
      }
      commit("EditJumpInfoMutation", jumpInfo);
    },
    EditVideoCommitInfoAction({ commit }, videoCommitInfo) {
      if (!videoCommitInfo) {
        throw new Error("传入的值不能为空");
      }
      commit("EditVideoCommitInfoMutation", videoCommitInfo);
    },
    EditVideoPlayInfoAction({ commit }, videoPlayInfo) {
      if (!videoPlayInfo) {
        throw new Error("传入的值不能为空");
      }
      commit("EditVideoPlayInfoMutation", videoPlayInfo);
    },
  },
  //加工state
  getters: {
    carInfo: (state) => state.carInfo,
    productInfo: (state) => state.productInfo,
    jumpInfo: (state) => state.jumpInfo,
    videoPlayInfo: (state) => state.videoInfo.videoPlayInfo,
    videoCommitInfo: (state) => state.videoInfo.videoCommitInfo,
    transactionCarInfo: (state) => state.transactionCarInfo,
    fullInfo: (state) => "姓名" + state.name,
  },
});
