import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'

import "./assets/style/reset.less"
import { Carousel, CarouselItem, InputNumber} from 'element-ui'
//个人函数库
import OwnLib from "./ownLib/ownLib";
Vue.prototype.$ownLib=new OwnLib();
Vue.config.productionTip = false

Vue.use(Carousel)
Vue.use(CarouselItem)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
